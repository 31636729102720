<template>
  <v-card class="pa-2">
    <div class="d-flex align-center">
      <v-card-title>
        Comentario
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        @click.stop="editComentario = !editComentario"
        small
        rounded
        color="primary"
      >
        <v-icon small left>mdi-pencil</v-icon>
        editar
      </v-btn>
    </div>
    <v-card-text v-if="!editComentario" class="mt-4">
      {{ value.comentario }}
    </v-card-text>
    <v-textarea
      class="ma-4"
      no-resize
      v-else
      v-model="comentarioNew"
      filled
      hide-details
    ></v-textarea>
    <v-card-actions v-if="editComentario">
      <v-spacer></v-spacer>
      <v-btn @click.stop="editComentario = false" small color="error">
        <v-icon left>mdi-close</v-icon>
        Cancelar
      </v-btn>
      <v-btn @click="saveComentario" small color="primary">
        <v-icon left small>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-card-actions>

    <v-divider></v-divider>

    <template v-if="value.pagada || value.comentarioPago" >
      <div class="d-flex align-center">
        <v-card-title>
          Comentario de pago
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          @click.stop="editComentarioPago = !editComentarioPago"
          small
          rounded
          color="primary"
        >
          <v-icon small left>mdi-pencil</v-icon>
          editar
        </v-btn>
      </div>
      <v-card-text v-if="!editComentarioPago" class="mt-4">
        {{ value.comentarioPago }}
      </v-card-text>
      <v-textarea
        class="ma-4"
        no-resize
        v-else
        v-model="comentarioPagoNew"
        filled
        hide-details
      ></v-textarea>
      <v-card-actions v-if="editComentarioPago">
        <v-spacer></v-spacer>
        <v-btn @click.stop="editComentarioPago = false" small color="error">
          <v-icon left>mdi-close</v-icon>
          Cancelar
        </v-btn>
        <v-btn @click="saveComentarioPago" small color="primary">
          <v-icon left small>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      editComentario: false,
      editComentarioPago: false,
      comentarioNew: null,
      comentarioPagoNew: null,
    };
  },
  methods: {
    async saveComentario() {
      await axios({
        method: 'POST',
        url: `${ process.env.VUE_APP_OUR_API_URL }/liquidaciones/comentario.php`,
        data: {
          comentarioPago: 0,
          comm : this.comentarioNew,
          idLiquidacion: this.value.idLiquidacion,
          token: this.$store.getters.getJwtEmpresa
        }
      })
      this.$emit('close');
      this.$emit('reload');
    },
    async saveComentarioPago() {
      await axios({
        method: 'POST',
        url: `${ process.env.VUE_APP_OUR_API_URL }/liquidaciones/comentario.php`,
        data: {
          comentarioPago: 1,
          comm : this.comentarioPagoNew,
          idLiquidacion: this.value.idLiquidacion,
          token: this.$store.getters.getJwtEmpresa
        }
      })
      this.$emit('close');
      this.$emit('reload');
    }
  },
  watch: {
    editComentario(v) {
      if (v) return;
      this.comentarioNew = null;
    },
    editComentarioPago(v) {
      if (v) return;
      this.comentarioPagoNew = null;
    },
  },
};
</script>

<style></style>
